import { Component, OnInit } from '@angular/core';
import { ArtistVideo } from 'src/app/models/artist-video';
import { ArtistVideoService } from 'src/app/services/artist-video.service';

@Component({
  selector: 'app-featured-videos',
  templateUrl: './featured-videos.component.html',
  styleUrls: ['./featured-videos.component.scss']
})
export class FeaturedVideosComponent implements OnInit {
  videos: ArtistVideo[] = [];
  loading = true;

  constructor(private artistVideos: ArtistVideoService) { }

  ngOnInit() {
    this.artistVideos.getArtistVideosByArtistId(0).subscribe(vids => {
      this.videos = vids.filter(x => x.isActive).sort((a,b) => {
        if (a.videoInfo.publishedAt < b.videoInfo.publishedAt) {
          return 1;
        }
        if (a.videoInfo.publishedAt > b.videoInfo.publishedAt) {
          return -1;
        }
        return 0;
      }).slice(0,2);
    })
    this.loading = false;
  }
}