export interface ArtistInfo {
    artistId: number;
    artistBio: string;
    artistMusic?: ArtistMusic[];
    artistName: string;
    artistRoute: string;
    artistSocialMedia?: ArtistSocialMedia[];
    calendarUrl?: string;
    isActive: boolean;
}

export interface ArtistMusic {
    isActive: boolean;
    platform: Platform;
    platformIcon: string;
    platformUrl: string;

}

export interface ArtistSocialMedia {
    isActive: boolean;
    platform: Platform;
    platformIcon: string;
    platformUrl: string;
}

export enum Platform {
    AppleMusic = 1,
    Spotify = 2,
    Tidal = 3,
    Youtube = 4,
    Vimeo = 5,
    Twitter = 6,
    Instagram = 7,
    TikTok = 8,
    Facebook = 9
}