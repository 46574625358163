import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ArtistVideo } from 'src/app/models/artist-video';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {
  @Input() video: ArtistVideo;
  embedUrl: SafeResourceUrl;

  constructor(private _sanitizer: DomSanitizer) {}

  createEmbedUrl(videoId: string) {
    return 'https://www.youtube.com/embed/' + videoId;
  }

   sanitizeVideo(videoUrl: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
   }

}
