import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Event } from '../models/event';

@Injectable({
  providedIn: 'root'
})
export class ArtistEventsService {

  constructor(private http: HttpClient) { }

  getEventsByArtistId(artistId: number): Observable<Event[]> {
    return this.http.get<Event[]>(`${environment.proxyUrl}/artist/${artistId}/events`);
  }
}
