import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Platform } from 'src/app/models/artist-information';
import { ArtistRelease } from 'src/app/models/artist-music';

@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.scss']
})
export class ReleaseComponent {
  @Input() release: ArtistRelease;

}
