import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ArtistVideo } from 'src/app/models/artist-video';
import { ArtistVideoService } from 'src/app/services/artist-video.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  videos: ArtistVideo[];

  constructor(private artistVideos: ArtistVideoService,
    private metaService: Meta,
    private titleService: Title) { }

  ngOnInit() {
    this.artistVideos.getArtistVideosByArtistId(0).subscribe(vids => {
      this.videos = vids.filter(x => x.isActive).sort((a,b) => {
        if (a.videoInfo.publishedAt < b.videoInfo.publishedAt) {
          return 1;
        }
        if (a.videoInfo.publishedAt > b.videoInfo.publishedAt) {
            return -1;
        }
        return 0;
      });
    })
    this.metaService.updateTag({ property: 'og:title', content: 'Dave Hill | Videos' });
    this.titleService.setTitle('Dave Hill | Videos');
  }

}
