import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ArtistMusic, Platform } from 'src/app/models/artist-information';
import { ArtistRelease } from 'src/app/models/artist-music';

@Component({
  selector: 'app-listen-now',
  templateUrl: './listen-now.component.html',
  styleUrls: ['./listen-now.component.scss']
})
export class ListenNowComponent {
  @Input() release: ArtistRelease;

  platformImageSource(platform: Platform) {
    switch (platform) {
      case Platform.AppleMusic:
        return '../../../../assets/logos/Apple_Music_Icon_RGB_lg_073120.png';
      case Platform.Spotify:
        return '../../../../assets/logos/Spotify_Icon_RGB_Green.png';
      case Platform.Tidal:
        return '../../../../assets/logos/icon-black-rgb.png';
      case Platform.Youtube:
        return '../../../../assets/logos/youtube_social_squircle_red.png';
      default:
        break;
    }
  }

}
