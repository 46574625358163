import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArtistMusic, ArtistSocialMedia, Platform } from 'src/app/models/artist-information';
import { ArtistInformationService } from 'src/app/services/artist-information.service';
import { EmailSubscriptionService } from 'src/app/services/email-subscription.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  loading = true;
  twitter: ArtistSocialMedia;
  instagram: ArtistSocialMedia;
  facebook: ArtistSocialMedia;
  tiktok: ArtistSocialMedia;
  appleMusic: ArtistMusic;
  spotify: ArtistMusic;
  tidal: ArtistMusic;
  youtube: ArtistMusic;

  emailSubscriptionForm = this.formBuilder.group({
    emailAddress: '',
  })

  constructor(private artistInformation: ArtistInformationService,
    private emailSubcription: EmailSubscriptionService,
    private formBuilder: FormBuilder) { 
    }

  ngOnInit() {
    this.artistInformation.getArtistInformationById(0).subscribe(
      artist => {
        this.twitter = artist.artistSocialMedia.find(link => link.platform == Platform.Twitter);
        this.instagram = artist.artistSocialMedia.find(link => link.platform == Platform.Instagram);
        this.facebook = artist.artistSocialMedia.find(link => link.platform == Platform.Facebook);
        this.tiktok = artist.artistSocialMedia.find(link => link.platform == Platform.TikTok);
        this.appleMusic = artist.artistMusic.find(link => link.platform == Platform.AppleMusic);
        this.spotify = artist.artistMusic.find(link => link.platform == Platform.Spotify);
        this.tidal = artist.artistMusic.find(link => link.platform == Platform.Tidal);
        this.youtube = artist.artistMusic.find(link => link.platform == Platform.Youtube);
        this.loading = false;
      }
    )
  }

  onSubmit(): void {
    this.emailSubcription.addEmailSubscription(this.emailSubscriptionForm.value.emailAddress).subscribe();
    this.emailSubscriptionForm.reset();
  }

}
