import { Component, OnInit } from '@angular/core';
import { ChildActivationStart } from '@angular/router';
import { ArtistInfo, ArtistMusic, ArtistSocialMedia, Platform } from 'src/app/models/artist-information';
import { ArtistInformationService } from 'src/app/services/artist-information.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  artistInfo: ArtistInfo;
  loading = true;
  twitter: ArtistSocialMedia;
  instagram: ArtistSocialMedia;
  facebook: ArtistSocialMedia;
  tiktok: ArtistSocialMedia;
  appleMusic: ArtistMusic;
  spotify: ArtistMusic;
  tidal: ArtistMusic;
  youtube: ArtistMusic;

  constructor(private artistInformation: ArtistInformationService) { }

  ngOnInit() {
    this.setLinks();
  }

  setLinks() {
    this.artistInformation.getArtistInformationById(0).subscribe(
      artist => {
        this.twitter = artist.artistSocialMedia.find(link => link.platform == Platform.Twitter);
        this.instagram = artist.artistSocialMedia.find(link => link.platform == Platform.Instagram);
        this.facebook = artist.artistSocialMedia.find(link => link.platform == Platform.Facebook);
        this.tiktok = artist.artistSocialMedia.find(link => link.platform == Platform.TikTok);
        this.appleMusic = artist.artistMusic.find(link => link.platform == Platform.AppleMusic);
        this.spotify = artist.artistMusic.find(link => link.platform == Platform.Spotify);
        this.tidal = artist.artistMusic.find(link => link.platform == Platform.Tidal);
        this.youtube = artist.artistMusic.find(link => link.platform == Platform.Youtube);
        this.loading = false;
      }
    )
  }


}
