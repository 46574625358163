import { Component, OnInit } from '@angular/core';
import { ArtistRelease } from 'src/app/models/artist-music';
import { Release } from 'src/app/models/release';
import { ArtistMusicService } from 'src/app/services/artist-music.service';

@Component({
  selector: 'app-featured-music',
  templateUrl: './featured-music.component.html',
  styleUrls: ['./featured-music.component.scss']
})
export class FeaturedMusicComponent implements OnInit {
  albums: ArtistRelease[] = [];

  constructor(private artistMusic: ArtistMusicService) { }

  ngOnInit() {
    this.artistMusic.getArtistAlbumsByArtistId(0).subscribe(releases => {
      const featureAlbums = releases.filter(x => x.isActive && !x.isSingle);
      featureAlbums.sort((a,b) => {
        if (a.albumReleaseDate < b.albumReleaseDate) {
          return 1;
        }
        if (a.albumReleaseDate > b.albumReleaseDate) {
          return -1;
        }
        return 0;
      });
      for (let i = 0; i < 2; i++ ) {
        this.albums.push(featureAlbums[i]);
      }
    })
  }

}
