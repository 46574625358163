import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectUnsubscribedError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmailSubscription } from '../models/email-subscription';
import  {v4 as uuid} from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class EmailSubscriptionService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(private http: HttpClient) { }

  addEmailSubscription(emailAddress: string): Observable<EmailSubscription> {
    const body: EmailSubscription = {
      email: emailAddress,
      isConfirmed: false,
      uuid: uuid(),
      timeStamp: new Date()
    };
    
    return this.http.put<EmailSubscription>(
      `${environment.proxyUrl}/email/${emailAddress}/subscribe`, 
      JSON.stringify(body),
      this.httpOptions
      );
  }
}
