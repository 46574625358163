import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArtistVideo, VideoInformation } from '../models/artist-video';

@Injectable({
  providedIn: 'root'
})
export class ArtistVideoService {
  videos: ArtistVideo[];
  videosWithAppendedInfo: ArtistVideo[] = [];

  constructor(private http: HttpClient) { }

  getArtistVideosByArtistId(artistId: number): Observable<ArtistVideo[]> {
    return this.http.get<ArtistVideo[]>(`${environment.proxyUrl}/artist/${artistId}/videos`);
  }
}