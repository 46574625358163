import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Event } from 'src/app/models/event';
import { ArtistEventsService } from 'src/app/services/artist-events.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  performances: Event[] = []

  constructor( private eventService: ArtistEventsService,
    private metaService: Meta,
    private titleService: Title) { }

  ngOnInit() {
    this.eventService.getEventsByArtistId(0).subscribe(events => {
      const today = new Date;
      const activeEvents = events.filter(x => x.isActive && new Date(x.eventDate) > today);
      this.performances = activeEvents.sort((a,b) => {
        if (a.eventDate < b.eventDate) {
          return 1;
        }
        if (a.eventDate > b.eventDate) {
          return -1;
        }
        return 0;
      });
    })
    this.metaService.updateTag({ property: 'og:title', content: 'Dave Hill | Events' });
    this.titleService.setTitle('Dave Hill | Events');
  }

}
