import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArtistRelease } from '../models/artist-music';

@Injectable({
  providedIn: 'root'
})
export class ArtistMusicService {

  constructor(private http: HttpClient) { }

  getArtistAlbumsByArtistId(artistId: number): Observable<ArtistRelease[]> {
    return this.http.get<ArtistRelease[]>(`${environment.proxyUrl}/artist/${artistId}/albums`);
  }
}
