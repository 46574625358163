import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  constructor(private metaService: Meta,
    private titleService: Title) { }

  ngOnInit() {
    this.metaService.updateTag({ property: 'og:title', content: 'Dave Hill | Home' });
    this.titleService.setTitle('Dave Hill | Home');
  }

}
