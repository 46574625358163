import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventsComponent } from './modules/events/events.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { MusicComponent } from './modules/music/music.component';
import { VideosComponent } from './modules/videos/videos.component';


const routes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'music',
    component: MusicComponent
  },
  {
    path: 'videos',
    component: VideosComponent
  },
  {
    path: 'events',
    component: EventsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
