import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArtistInfo } from '../models/artist-information';


@Injectable({
  providedIn: 'root'
})
export class ArtistInformationService {
  private artistUrl = '/artistInformation';

  constructor(private http: HttpClient) { }

  getArtistInformation(): Observable<ArtistInfo[]> {
    return this.http.get<ArtistInfo[]>(this.artistUrl);
  }

  getArtistInformationById(artistId: number): Observable<ArtistInfo> {
    return this.http.get<ArtistInfo>(`${environment.proxyUrl}${this.artistUrl}/${artistId}`);
  }

}
