import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ArtistRelease } from 'src/app/models/artist-music';
import { ArtistMusicService } from 'src/app/services/artist-music.service';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements OnInit {
  albums: ArtistRelease[] = [];
  singles: ArtistRelease[] = [];

  constructor(private artistMusic: ArtistMusicService,
    private metaService: Meta,
    private titleService: Title) { }

  ngOnInit() {
    this.artistMusic.getArtistAlbumsByArtistId(0).subscribe(releases => {
      this.albums = releases.filter(x => x.isActive && !x.isSingle)
      this.albums.sort((a,b) => {
        if (a.albumReleaseDate < b.albumReleaseDate) {
          return 1;
        }
        if (a.albumReleaseDate > b.albumReleaseDate) {
          return -1;
        }
        return 0;
          });
    })
    this.metaService.updateTag({ property: 'og:title', content: 'Dave Hill | Music' });
    this.titleService.setTitle('Dave Hill | Music');
  }

}
