import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MusicComponent } from './modules/music/music.component';
import { EventsComponent } from './modules/events/events.component';
import { VideosComponent } from './modules/videos/videos.component';
import { HttpClientModule } from '@angular/common/http';
import { ReleaseComponent } from './modules/music/release/release.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { FeaturedMusicComponent } from './modules/homepage/featured-music/featured-music.component';
import { FeaturedVideosComponent } from './modules/homepage/featured-videos/featured-videos.component';
import { FeaturedEventsComponent } from './modules/homepage/featured-events/featured-events.component';
import { HeaderComponent } from './modules/homepage/header/header.component';
import { FooterComponent } from './modules/homepage/footer/footer.component';
import { VideoComponent } from './modules/videos/video/video.component';
import { PerformanceComponent } from './modules/events/performance/performance.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListenNowComponent } from './modules/music/listen-now/listen-now.component';


@NgModule({
  declarations: [
    AppComponent,
    MusicComponent,
    VideosComponent,
    EventsComponent,
    ReleaseComponent,
    HomepageComponent,
    FeaturedMusicComponent,
    FeaturedVideosComponent,
    FeaturedEventsComponent,
    HeaderComponent,
    FooterComponent,
    VideoComponent,
    PerformanceComponent,
    ListenNowComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
