import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/models/event';
import { ArtistEventsService } from 'src/app/services/artist-events.service';

@Component({
  selector: 'app-featured-events',
  templateUrl: './featured-events.component.html',
  styleUrls: ['./featured-events.component.scss']
})
export class FeaturedEventsComponent implements OnInit {
  performances: Event[] = [];

  constructor(private eventService: ArtistEventsService) { }

  ngOnInit() {
    const today = new Date;
    this.eventService.getEventsByArtistId(0).subscribe(events => {
      const featureEvents = events.filter(x => x.isActive && new Date(x.eventDate) > today);
      featureEvents.sort((a,b) => {
        if (a.eventDate < b.eventDate) {
          return 1;
        }
        if (a.eventDate > b.eventDate) {
          return -1;
        }
        return 0;
      });
      if (featureEvents.length > 1) {
        for (let i = 0; i < 2; i++ ) {
          this.performances.push(featureEvents[i]);
        }
      } else {
        this.performances = featureEvents;
      }
    })
  }

}
